<template>
  <!-- Run on CTRL and Meta for Windows & macOS (which uses cmd = meta) -->
  <div @keydown.ctrl.z="doUndo" @keydown.meta.z="doUndo">
    <ContextMenu ref="context">
      <ContextItem @activate="copyCell">{{$gettext('Copy cell')}}</ContextItem>
      <ContextItem @activate="pasteCell">{{$gettext('Paste cell')}}</ContextItem>
      <ContextItem @activate="copyToRow">{{$gettext('Copy cell to row')}}</ContextItem>
      <ContextItem @activate="pasteToRow">{{$gettext('Paste cell to row')}}</ContextItem>
      <ContextItem v-if="history.length" @activate="doUndo">{{$gettext('Undo last action')}}</ContextItem>
    </ContextMenu>

    <Loader v-if="report===false"/>
    <div v-else>

      <div v-if="report&&report.currentVersion&&report.spreadsheets.length" class="Spreadsheet">
        <div class="Spreadsheet_TopBar">
          <ReportInfo class="Spreadsheet_TopBar_Info" :reportVersion="report.currentVersion._id" :slim="true" :back="true"/>
          <div ref="hint" class="Spreadsheet_TopBar_Hint" aria-live="polite"></div>
          <ul class="Spreadsheet_TopBar_Tabs">
            <template v-for="(ss, index) in report.spreadsheets">
              <li :key="ss.title" :class="['Spreadsheet_TopBar_Tabs_Tab', index==currentPage?'_active':'']" v-if="!ss.deleted">
                <button class="Spreadsheet_TopBar_Tabs_Tab_Btn" :aria-current="index==currentPage" aria-controls="Spreadsheet" @click="switchToSheet(index)">{{ss.title}}</button>
                <Button class="Spreadsheet_TopBar_Tabs_Tab_Opts" type="icon" :icon="['solid', 'chevron-down']" :aria-expanded="ss.opts?'true':'false'" @click="() => { if(ss.opts) { ss.opts = !ss.opts } else { ss.opts = true }; $forceUpdate(); }">{{$gettext('Options')}}</Button>
                <ul :class="['TabOpts', index!=currentPage?'_offset':'']" v-if="ss.opts">
                  <li class="TabOpts_Opt"><Button class="TabOpts_Opt_Btn" type="transparent" size="small" @click="sheetSettings(ss)">{{$gettext('Settings')}}</Button></li>
                  <li class="TabOpts_Opt"><Button class="TabOpts_Opt_Btn" type="transparent" size="small" @click="deleteSheet(ss)">{{$gettext('Delete')}}</Button></li>
                </ul>
              </li>
            </template>
            <li :class="['Spreadsheet_TopBar_Tabs_Tab', currentPage==report.spreadsheets.length?'_active':'']">
              <button class="Spreadsheet_TopBar_Tabs_Tab_Btn" :aria-current="currentPage==report.spreadsheets.length" @click="() => { currentPage = report.spreadsheets.length}">{{$gettext('New Sheet')}}</button>
            </li>
          </ul>
          <ButtonSet class="Spreadsheet_TopBar_Buttons" ref="btns">
            <Button type="icon" size="small" :icon="['solid', 'expand-arrows-alt']" class="maxbtn" ref="maxBtn" @click.prevent="maxSheet">{{$gettext('Maximise')}}</Button>
            <Button type="icon" size="small" :icon="['solid', 'copy']" class="copysheetbtn" @click.prevent="copySheet">{{$gettext('Copy')}}</Button>
          </ButtonSet>
        </div>

        <div id="Spreadsheet" :class="['Spreadsheet_Container', hasFooter?'_hasFooter':'']" ref="sheetcontainer" v-if="report.spreadsheets[currentPage]&&report.spreadsheets[currentPage].rows&&report.spreadsheets[currentPage].rows.length" @scroll="onScroll">
          <template v-if="report.spreadsheets[currentPage].rows[0].columns">
            <table ref="table" tabindex="-1" role="grid" class="Spreadsheet_Container_Table" @keydown="enterFirst">
              <thead class="Spreadsheet_Container_Table_Head SSHead">
                <tr>
                  <th class="SSHead_Header _topLeft">
                    <div class="SSHead_Header_Inner" ref="columnheader">
                      <div class="SSHead_Header_Inner_Head">{{$gettext('Section')}}</div>
                    </div>
                  </th>
                  <th v-for="(column,index) in report.spreadsheets[currentPage].rows[0].columns" v-bind:key="'column-' + index" class="SSHead_Header">
                    <div class="SSHead_Header_Inner" ref="columnheader">
                      <div class="SSHead_Header_Inner_Head">
                        <Icon v-if="column.type=='page'" type="regular" icon="file" read="Page: "/>
                        <Icon v-else-if="column.type=='component'" type="solid" icon="puzzle-piece" read="Component: "/>
                        {{column.header}}
                      </div>
                      <Button v-if="column.type=='page'" type="icon" :icon="['regular', 'edit']" size="small" @click="$refs.editpagemodal.show(column.id)">{{$gettext('Edit page')}} {{column.header}}</Button>
                      <Button v-else-if="column.type=='component'" type="icon" :icon="['regular', 'edit']" size="small" @click="$refs.editcomponentmodal.show(column.id)">{{$gettext('Edit component')}} {{column.header}}</Button>
                    </div>
                  </th>
                  <th class="SSHead_Header_End">&nbsp;</th>
                </tr>
              </thead>
              <tbody class="Spreadsheet_Container_Table_Body SSBody">
                <tr class="SSBody_URLs">
                  <th class="SSBody_URLs_Header" v-translate>
                    URL
                  </th>
                  <td class="SSBody_URLs_Item" v-for="(column,index) in report.spreadsheets[currentPage].rows[0].columns" v-bind:key="'url-column-' + index">
                    <a v-if="column.type=='page'" :href="`${column.page.host}${column.page.path}`" target="_blank">
                      <span>{{ column.page.host }}{{ column.page.path }}</span>
                      <Icon class="icon" type="solid" icon="external-link-alt" aria-label="(Opens in new tab)"/></a>
                  </td>
                  <td :rowspan="report.spreadsheets[currentPage].rows.length+900" class="SSBody_Section_AddColumn">
                    <div class="SSBody_Section_AddColumn_Inner" ref="addcolumn">
                      <div class="SSBody_Section_AddColumn_Inner_Btns" ref="addcolumnbtns">
                        <Button type="border" @click="$refs.pagemodal.show()">{{$gettext('Add a page')}}</Button>
                        <Button type="border" @click="$refs.componentmodal.show()">{{$gettext('Add a component')}}</Button>
                      </div>
                    </div>
                  </td>
                </tr>
                <template v-for="(row,index) in report.spreadsheets[currentPage].rows">
                  <tr v-if="row.newSection" v-bind:key="`rowhead-${row.template._id}-${index}`" class="SSBody_Section">
                    <th class="SSBody_Section_Header">
                      <div :class="'SSBody_Section_Header_Inner'" ref="rowheader">{{row.template.section}}</div>
                    </th>
                    <td :colspan="row.columns.length" class="SSBody_Section_Fill">&nbsp;</td>
                  </tr>
                  <tr v-if="collapsedSections.indexOf(row.template.section)<0"  v-bind:key="`row-${row.template._id}-${index}`" class="SSBody_Row">
                    <th class="SSBody_Row_Header">
                      <div class="SSBody_Row_Header_Inner" ref="rowheader">
                        <div class="SSBody_Row_Header_Inner_Index" v-if="row.template.ref!='0'||row.template.header!='Notes'">{{ row.template.ref }}</div>
                        <div class="SSBody_Row_Header_Inner_Contents">
                          <vue-markdown :key="`${row.template.ref}-h`" :html="false">{{ row.template.header }}</vue-markdown>
                          <vue-markdown :key="`${row.template.ref}-d`" :html="false">{{ row.template.details }}</vue-markdown>
                        </div>
                      </div>
                    </th>
                    <template v-for="column in row.columns" :key="`${row.template._id}${column.id}_${currentPage}`">
                      <SpreadsheetCell :aria-label="'Row: '+row.template.header+', Column: '+column.header"
                        @open-modal="openModal"
                        @open-modal-link="openModalLink"
                        :ref="`cell-${row.template._id}${column.id}_${currentPage}`"
                        :row="row.template._id"
                        :column="column.id"
                        :page="parseInt(currentPage)"
                        :type="column.type"
                        :history="history"
                        v-model:prepop="column.cell"
                        :notesOnly="row.template.header=='Notes'"
                        :rowReference="row.template.ref"
                        :permaItem="column.page && row.template.ref == '2.4.2' && column.page.title ? `Page title: ${column.page.title}` : ''"
                        @traverse="traverse"
                        @setHint="setHint"
                        @focus="() => { focussedRow = index; }"
                        @context="$refs.context.open"
                        @copyCell="copyCell"
                        @keydown.ctrl.z="doUndo" @keydown.meta.z="doUndo"
                        @changed="cellChanged"
                        @goToIssue="goToIssue"
                        :forceRender="copyMode"
                        :copyMode="copyMode"/>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="Spreadsheet_Container_Overlay" ref="ssoverlay">
              <div class="Spreadsheet_Container_Overlay_Splitter"></div>
            </div>
          </template>
          <Loader v-else/>
        </div>
        <div v-else class="Spreadsheet_Container AddSheet">
          <Body title="Create a tracking spreadsheet" :withMenu="true">
            <p v-translate>Adding a spreadsheet to your report helps you to track progress and locate issues. We have a number of pre-made templates to help you get to testing without having to remember the exact WCAG references.</p>
            <p v-translate>Choose a template:</p>

            <div class="AddSheet_Options">
              <div class="AddSheet_Options_Option" v-for="(template, tid) in templates" :key="tid">
                <h3>{{ template }}</h3>
                <p>{{ templateDescs[tid] }}</p>
                <Button size="small" type="primary" @click="useSheet(tid)">{{$gettext('Use this one')}}</Button>
              </div>
            </div>

            <template v-if="previousSheetsFiltered.length">
              <p v-translate>or import a previous page</p>

              <div class="AddSheet_Options">
                <template v-for="page in previousSheetsFiltered" :key="page._id">
                  <div class="AddSheet_Options_Option">
                    <h3>{{ page.title }} {{$gettext('from version')}} {{ page.reportVersion.version }}</h3>
                    <p>{{$gettext('Template:')}} {{ page.template.name }}</p>
                    <Button size="small" type="primary" @click="importSheet(page._id)">{{$gettext('Import this one')}}</Button>
                  </div>
                </template>
              </div>
            </template>
          </Body>
        </div>

        <IssueInstanceModal ref="modal" v-bind:report="report.currentVersion._id" @success="newInstance"/>
        <EditPageModal ref="editpagemodal" @edited="$apollo.queries.report.refetch()"/>
        <EditComponentModal :report="report.currentVersion._id" ref="editcomponentmodal" @edited="$apollo.queries.report.refetch()"/>

        <PageModal :report="report.currentVersion._id" :components="report.components" ref="pagemodal" @added="$apollo.queries.report.refetch()"/>
        <ComponentModal :report="report.currentVersion._id" ref="componentmodal" @added="$apollo.queries.report.refetch()"/>

        <SheetSettingsModal ref="sheetsettingsmodal" @edited="(number, title) => { report.spreadsheets[number].title = title; switchToSheet(number, true) }"/>
        <!-- <IssueFragment ref="issuefragment" @closed="closeFragment" /> -->
        <IssueInstanceViewModal ref="issueviewmodal" @closed="closeFragment" /> <!-- @resolved="resolvedFromModal" @mutated="mutateIssue" /> -->
        <!-- <IssueFragment ref="issuefragment" @closed="closeFragment" @goToPage="goToPage" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney"/> -->
      </div>
      <div class="AddSheet" v-else>
        <ReportInfo :reportVersion="report.currentVersion._id"/>
        <Body title="Create a tracking spreadsheet" :withMenu="true">
          <p>{{ $gettext( 'Adding a spreadsheet to your %{projectString} helps you to track and locate issues. We have a number of pre-made templates to help you get to testing without having to remember the exact WCAG references.', { projectString: $hugrConfig.projectString } ) }}</p>
          <p v-translate>Choose a template:</p>

          <div class="AddSheet_Options">
            <div class="AddSheet_Options_Option" v-for="(template, tid) in templates" :key="tid">
              <h3>{{ template }}</h3>
              <p>{{ templateDescs[tid] }}</p>
              <Button size="small" type="primary" @click="useSheet(tid)">{{$gettext('Use this one')}}</Button>
            </div>
          </div>

          <template v-if="previousSheetsFiltered.length">
            <p v-translate>or import a previous page</p>

            <div class="AddSheet_Options">
              <template v-for="page in previousSheetsFiltered" :key="page._id">
                <div class="AddSheet_Options_Option">
                  <h3>{{ page.title }} {{$gettext('from version')}} {{ page.reportVersion.version }}</h3>
                  <p>{{$gettext('Template:')}} {{ page.template.name }}</p>
                  <Button size="small" type="primary" @click="importSheet(page._id)">{{$gettext('Import this one')}}</Button>
                </div>
              </template>
            </div>
          </template>
        </Body>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'md5';

import gql from 'graphql-tag';
import UIContextMenu from '@/components/UI/ContextMenu';
import UIContextItem from '@/components/UI/ContextItem';

import SpreadsheetCell from '@/components/SpreadsheetCell';

import IssueInstanceModal from '@/modals/IssueInstance/Create';
import IssueInstanceViewModal from '@/modals/IssueInstance/View';

import PageModal from '@/modals/Page/Create';
import ComponentModal from '@/modals/Component/Create';

import EditPageModal from '@/modals/Page/Edit';
import EditComponentModal from '@/modals/Component/Edit';

import SheetSettingsModal from '@/modals/SheetSettings';

// import IssueFragment from '@/fragments/Issue';

import UIIcon from '@/components/UI/Icon';

let gCurrentSheet = 0;

export default {
  name: 'ReportsSpreadsheetView',
  created() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            statementLink
          }
        }
      `,
    } ).then( res => {
      this.hasFooter = res.data.settings.statementLink;
    } ).catch( () => {
      this.$alerts.coded( 'E001', 'FXXX' ); //see notifications spreadsheet
    } );
  },
  data() {
    return {
      identifier: this.$route.params.identifier.replace( '%20', ' ' ),
      version: this.$route.params.version,

      currentPage: 0,
      sections: [],
      rows: false,
      collapsedSections: [],

      focussedRow: null,

      report: false,

      previousSheets: false,

      templates: {},
      templateDescs: {},
      promoted: false,

      new_template: 0,
      lastSection: '',

      stickleft: true,
      sticktop: true,

      history: [],

      // modalCell: false, //cell currently open in modal
      // modalItem: false //item in cell
      modalData: false, //replaced the above

      copiedData: false, //because paste is a bitch

      changeCache: [], //store socket pushed changes to sheets to use later

      copyMode: false,
      hasFooter: false,
    };
  },
  mounted() {
    this.$apollo.query( {
      query: gql`
        {
          templates: SpreadsheetTemplates {
            _id,
            name,
            description,
            published,
            promoted
          }
        }
      `,
    } ).then( res => {
      const templates = {};
      for( const template of res.data.templates ) {
        if( template.published ) {
          this.templateDescs[template._id] = template.description;
          if( template.promoted ) {
            this.promoted = template;
          } else {
            templates[template._id] = template.name;
          }
        }
      }
      this.new_template = this.promoted._id;
      const p = {};
      // eslint-disable-next-line no-unused-expressions
      p[this.promoted._id] = `${this.promoted.name} (recommended)`;
      this.templates = {
        ...p,
        ...templates,
      };
    } );

    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Assessment Spreadsheet',
      },
    } );
  },
  watch: {
    $route() {
      if( this.$route.name == 'ReportsSpreadsheet' || this.$route.name == 'ReportsSpreadsheetVersion' ) {
        this.identifier = this.$route.params.identifier.replace( '%20', ' ' );
        this.version = this.$route.params.version;
        this.$apollo.queries.report.refetch();
      }
    },
    report() {
      if( this.report && this.report.spreadsheets[this.currentPage] ) {
        this.findASheet();
        const observer = this.$apollo.subscribe( {
          query: gql`
            subscription SpreadsheetCellChanged($spreadsheet: String!) {
              cell: SpreadsheetCellChanged(spreadsheet: $spreadsheet) {
                _id,
                row,
                column,
                type,
                sheet
                items {
                  done,
                  text,
                  instance,
                }
              }
            }
          `,
          variables: {
            spreadsheet: this.report?.spreadsheets[this.currentPage]?._id,
          },
        } );

        observer.subscribe( {
          next: res => {
            //update data
            this.cellChanged( res.data.cell );
            //update visual if applicable
            if( gCurrentSheet == res.data.cell.sheet && this.$refs[`cell-${res.data.cell.row}${res.data.cell.column}_${res.data.cell.sheet}`] ) {
              this.$refs[`cell-${res.data.cell.row}${res.data.cell.column}_${res.data.cell.sheet}`][0].setItems( res.data.cell.items );
            } else {
              this.changeCache.push( res.data.cell );
            }
          },
          error: () => {
          },
        } );
      } else {
        this.getPreviousSheets();
      }
    },
  },
  apollo: {
    report: { //TODO version?
      query() {
        const fragment = gql`
          fragment spreadsheetReportFragment on Report {
            _id,
            spreadsheets {
              _id
              number,
              title,
              userJourney {
                _id
                title
              }
              deleted,
              rows {
                newSection # keep this to show it's still a real sheet
              #   template {
              #     _id
              #     section
              #     ref
              #     header
              #     details
              #   }
              #   columns {
              #     id,
              #     header,
              #     type,

              #     cell {
              #       _id
              #       row
              #       column
              #       type
              #       items {
              #         done,
              #         text,
              #         instance,
              #       }
              #     }
              #   }
              }
            }

            currentVersion {
              _id
            }

            components {
              _id,
              identifier,
              description,
              futurePagesAdded,
              pages {
                _id,
                name
              }
              issues {
                _id
              }
              screenshot {
                _id
              }
            }
          }
        `;
        if( typeof this.version == 'undefined' ) {
          return gql`
             query Report($ident: String!) {
               report: Report(identifier: $ident) {
                 ... spreadsheetReportFragment
               }
             }
             ${fragment}
           `;
        }

          return gql`
             query Report($ident: String!, $version: String!) {
               report: ReportWithVersion(identifier: $ident, version: $version) {
                 ... spreadsheetReportFragment
               }
             }
             ${fragment}
           `;

      },
      variables() {
        return {
          ident: this.identifier,
          version: this.version,
        };
      },
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    md5,
    doUndo() {
      const toUndo = this.history.pop();
      if ( !toUndo ) return;

      const cell = () => this.$refs[toUndo.id][0];

      switch( toUndo.change ) {
        case 'pasteCell':
          cell().paste( toUndo.from, false );

          break;
        case 'rmItem':
          cell().activate( toUndo.from.text, toUndo.from.instance, toUndo.from.done );

          break;
        case 'copyToRow':
          // Take the `from` array and apply it to each cell.
          this.restoreRow( toUndo );

          break;
        case 'clearCell':
          cell().paste( toUndo.from, false );

          break;
      }
    },
    switchToSheet( index, force = false ) {
      if( !this.report.spreadsheets[index].rows[0].columns || force ) { //only load if not already loaded
        this.$apollo.query( {
          query: gql`
            query SpreadsheetPage($reportVersion: ObjectID!, $page: Int!) {
              page: SpreadsheetPage(reportVersion: $reportVersion, page: $page) {
                _id
                number,
                title,
                userJourney {
                  _id
                  title
                }
                deleted,
                rows {
                  newSection
                  template {
                    _id
                    section
                    ref
                    header
                    details
                  }
                  columns {
                    id,
                    header,
                    type,
                    page {
                      _id
                      host
                      path
                      title
                    }

                    cell {
                      _id
                      row
                      column
                      type
                      items {
                        done,
                        text,
                        instance,
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            reportVersion: this.report.currentVersion._id,
            page: parseInt( index ),
          },
          fetchPolicy: 'no-cache',
        } ).then( res => {
          this.report.spreadsheets[res.data.page.number] = res.data.page;
          this.$forceUpdate();
        } ).catch( () => {
          this.$alerts.error( `Couldn't load the sheet`, 'Sorry but the spreadsheet could not be loaded, please refresh the page and try again.' );
        } );
      }
      gCurrentSheet = index;
      this.currentPage = index;
    },
    deleteSheet( sheet ) {
      sheet.opts = false;
      this.$forceUpdate();
      this.$confirm.simple( 'Are you sure you want to delete this sheet?' ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation deleteSpreadsheetPage($page: ObjectID!) {
                deleteSpreadsheetPage(page: $page)
              }
            `,
            variables: {
              page: sheet._id,
            },
          } ).then( () => {
            sheet.deleted = true;
            this.findASheet();
          } ).catch( err => {
            this.$alerts.error( `Couldn't delete the sheet`, 'Sorry but the spreadsheet could not be deleted, please try again.' );
          } );
        }
      } );
    },
    sheetSettings( sheet ) {
      sheet.opts = false;
      this.$forceUpdate();
      this.$refs.sheetsettingsmodal.show( sheet, this.report.currentVersion._id );
    },
    /**
     * Locates an active sheet in memory to display as the initial tab
     */
    findASheet() {
      let foundSheet = false;
      for( const i in this.report.spreadsheets ) {
        if( this.report.spreadsheets.hasOwnProperty( i ) ) {
          if( this.report.spreadsheets[i].deleted == false && foundSheet == false ) {
            this.switchToSheet( i );
            foundSheet = true;

            break;
          }
        }
      }
      if( !foundSheet ) {
        this.currentPage = this.report.spreadsheets.length;
      }
    },
    /**
     * Function to get previous sheets not in this version to import
     */
    getPreviousSheets() {
      this.$apollo.query( {
        query: gql`
          query previousSpreadsheetPages($report: ObjectID!) {
            previousSpreadsheetPages(report: $report) {
              _id,
              title,
              number,
              deleted,
              template {
                _id,
                name,
                description
              },
              reportVersion {
                _id,
                version
              }
            }
          }
        `,
        variables: {
          report: this.report._id,
        },
      } ).then( res => {
        this.previousSheets = res.data.previousSpreadsheetPages;
      } ).catch( () => {} );
    },
    /**
     * Sets the hint text at the top of the screen
     */
    setHint( text ) {
      this.$refs.hint.innerText = text;
    },
    /**
     * Imports a spreadsheet page from a given id to to this report version
     */
    importSheet( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation importSpreadsheetPage($reportVersion: ObjectID!, $id: ObjectID!) {
            importSpreadsheetPage(reportVersion: $reportVersion, id: $id) {
              _id
            }
          }
        `,
        variables: {
          reportVersion: this.report.currentVersion._id,
          id,
        },
      } ).then( () => {
        setTimeout( () => {
          this.$apollo.queries.report.refetch();
        }, 100 );
      } ).catch( () => {

      } );
    },
    /**
     * Makes a new sheet using the promoted template
     */
    usePromoted() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addSpreadsheetPage($page: SpreadsheetPageInput!) {
            addSpreadsheetPage(page: $page) {
              _id
            }
          }
        `,
        variables: {
          page: {
            reportVersion: this.report.currentVersion._id,
            template: this.promoted._id,
            title: 'Sheet 1',
            number: 0,
          },
        },
      } ).then( () => {
        setTimeout( () => {
          this.$apollo.queries.report.refetch();
        }, 100 );
      } ).catch( () => {

      } );
    },
    /**
     * Makes a new sheet using a given template
     */
    useSheet( sheet ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addSpreadsheetPage($page: SpreadsheetPageInput!) {
            addSpreadsheetPage(page: $page) {
              _id
            }
          }
        `,
        variables: {
          page: {
            reportVersion: this.report.currentVersion._id,
            template: sheet,
            title: `Sheet ${this.currentPage + 1}`,
            number: this.currentPage,
          },
        },
      } ).then( () => {
        setTimeout( () => {
          this.$apollo.queries.report.refetch();
        }, 100 );
      } ).catch( () => {

      } );
    },
    /**
     * Makes a new sheet using the template selected in this.new_template
     */
    newSheet() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addSpreadsheetPage($page: SpreadsheetPageInput!) {
            addSpreadsheetPage(page: $page) {
              _id
            }
          }
        `,
        variables: {
          page: {
            reportVersion: this.report.currentVersion._id,
            template: this.new_template,
            title: `Sheet ${this.currentPage + 1}`,
            number: this.currentPage,
          },
        },
      } ).then( () => {
        setTimeout( () => {
          this.$apollo.queries.report.refetch();
        }, 100 );
      } ).catch( () => {

      } );
    },
    /**
     * Enters the first cell if any arrow is pressed from anywhere in the table that isn't a cell
     */
    enterFirst( e ) {
      const isArrow = e.key == 'ArrowRight' || e.key == 'ArrowLeft' || e.key == 'ArrowUp' || e.key == 'ArrowDown';
      const notOnCell = !document.activeElement.classList.contains( 'SSCell' );
      const notInCell = !(
                            document.activeElement.classList[0]?.indexOf( 'SSCell' ) >= 0
                            || document.activeElement.parentElement?.classList[0]?.indexOf( 'SSCell' ) >= 0
                            || document.activeElement.parentElement?.parentElement?.classList[0]?.indexOf( 'SSCell' ) >= 0
                          );
      if( isArrow && notOnCell && notInCell ) {
        this.$refs.table.getElementsByClassName( 'SSCell' )[0].focus();
      }
    },
    /**
     * Traverses the cells of the table
     */
    traverse( e ) {
      switch( e.key ) {
        case 'ArrowRight': {
          this.doFocus( e.srcElement.nextElementSibling );

          break;
        }
        case 'ArrowLeft': {
          this.doFocus( e.srcElement.previousElementSibling );

          break;
        }
        case 'ArrowUp': {
          let children = e.srcElement.parentElement?.previousElementSibling?.children;
          if( children[0] ) {
            if( children[0].classList.contains( 'SSBody_Section_Header' ) ) {
              children = e.srcElement.parentElement?.previousElementSibling?.previousElementSibling?.children;
            }
            if( children ) {
              this.doFocus( children[e.srcElement.cellIndex] );
            }
          }

          break;
        }
        case 'ArrowDown': {
          let children = e.srcElement.parentElement?.nextElementSibling?.children;
          if( children[0] ) {
            if( children[0].classList.contains( 'SSBody_Section_Header' ) ) {
              children = e.srcElement.parentElement?.nextElementSibling?.nextElementSibling?.children;
            }
            if( children ) {
              this.doFocus( children[e.srcElement.cellIndex] );
            }
          }

          break;
        }
      }
    },
    doFocus( el ) {
      if( el && el.tagName == 'TD' ) {
        // el.focus(); //XXX: should this check is a spreadsheet cell?
        if( this.$refs[el.getAttribute( 'data-ref' )] && this.$refs[el.getAttribute( 'data-ref' )][0] ) {
          this.$refs[el.getAttribute( 'data-ref' )][0].focus();
        }
      }
    },

    onScroll( evt ) {
      if( this.stickleft ) {
        Array.from( this.$refs.rowheader, e => { e.style.left = `${evt.target.scrollLeft}px` } );
        if( evt.target.scrollLeft > 1 ) {
          Array.from( this.$refs.rowheader, e => e.classList.add( '_scrolled' ) );
          this.$refs.ssoverlay.classList.add( '_scrolled' );
        } else {
          Array.from( this.$refs.rowheader, e => e.classList.remove( '_scrolled' ) );
          this.$refs.ssoverlay.classList.remove( '_scrolled' );
        }
      }
      if( this.sticktop ) {
        Array.from( this.$refs.columnheader, e => { e.style.top = `${evt.target.scrollTop}px` } );
        if( evt.target.scrollTop > 10 ) {
          Array.from( this.$refs.columnheader, e => e.classList.add( '_scrolled' ) );
        } else {
          Array.from( this.$refs.columnheader, e => e.classList.remove( '_scrolled' ) );
        }
      }
      this.$refs.addcolumnbtns.style.top = `${evt.target.scrollTop}px`;
    },
    openModal( row, column, sheet, type, item, text = "" ) {
      this.$refs.modal.show();
      this.$refs.modal.setRow( row );
      if( type == 'page' ) {
        this.$refs.modal.presetPage( column );
      } else if( type == 'component' ) {
        this.$refs.modal.presetComponent( column );
      }
      if( text != "" ) {
        this.$refs.modal.presetReason( text );
      }
      this.modalData = {
        cell: `cell-${row}${column}_${sheet}`,
        type,
        row,
        column,
        item,
      };
    },
    openModalLink( row, column, sheet, type, item ) {
      this.$refs['modal-link'].show();
      this.modalData = {
        cell: `cell-${row}${column}_${sheet}`,
        type,
        row,
        column,
        item,
      };
    },
    newInstance( result ) {
      this.$refs[this.modalData.cell][0].instanceAdded( this.modalData.item, result.identifier, result.severity );
      setTimeout( () => {
        this.$refs[this.modalData.cell][0].focus();
      }, 100 );
    },
    // linkInstance: function(i) {
    //   this.$refs[this.modalData.cell][0].instanceAdded(this.modalData.item, i);
    //   if(this.modalData.type=='page') {
    //     this.jwtemit(['PUT_ISSUEINSTANCEGROUP_ADDONE', {
    //       parent: i,
    //       page: this.modalData.column
    //     }])
    //   }
    // },
    restoreRow( { from } ) {
      from.forEach( cellToUndo => {
        const cell = this.$refs[cellToUndo.id][0];
        cell.paste( cellToUndo.items, false );
      } );
    },
    copyToRow( e, props ) {
      this.$confirm.simple( 'This will overwrite all cells in the row' ).then( result => {
        if( result ) {
          const { cell } = props;
          const { children } = cell.parentElement;
          const data = this.$refs[`cell-${props.row}${props.column}_${props.page}`][0].copy();
          const historyEntry = { change: 'copyToRow', from: [], to: data, id: null };

          for( const child of children ) {
            if( child.tagName == 'TD' ) {
              const cell = this.$refs[child.getAttribute( 'data-ref' )][0];
              const dataToPush = cell.cloneData();
              dataToPush.id = child.getAttribute( 'data-ref' );
              historyEntry.from.push( dataToPush );
              cell.paste( data, false, false );
            }
          }

          this.history.push( historyEntry );
        }
      } );
    },
    pasteToRow( _, { cell: { parentElement: { children } } } ) {
      this.$confirm.simple( 'This will overwrite all cells in the row' ).then( result => {
        if ( !result ) return;

        for( const child of children ) {
          if( child.tagName == 'TD' ) {
            this.$refs[child.getAttribute( 'data-ref' )][0].paste( this.copiedData );
          }
        }
      } );
    },
    copyCell( _, props ) {
      const cellRef = `cell-${props.row}${props.column}_${props.page}`;
      this.copiedData = this.$refs[cellRef][0].copy();
      this.$refs[cellRef][0].doCopy();
    },
    pasteCell( _, props ) {
      const checkData = this.$refs[`cell-${props.row}${props.column}_${props.page}`][0].copy()[0];
      if( checkData.text != '' ) {
        this.$confirm.simple( 'This cell has data in it which will be overwritten' ).then( result => {
          if( result ) {
            this.$refs[`cell-${props.row}${props.column}_${props.page}`][0].paste( this.copiedData );
          }
        } );
      } else {
        this.$refs[`cell-${props.row}${props.column}_${props.page}`][0].paste( this.copiedData );
      }
    },
    toggleSection( e ) {
      const btn = e.target;
      const section = e.target.innerText;
      if( this.collapsedSections.indexOf( section ) >= 0 ) {
        this.collapsedSections.splice( this.collapsedSections.indexOf( section ), 1 );
        btn.setAttribute( 'aria-expanded', true );
      } else {
        this.collapsedSections.push( section );
        btn.setAttribute( 'aria-expanded', false );
      }
    },
    maxSheet() {
      const cont = this.$refs['sheetcontainer'];
      //TODO: fix
      // eslint-disable-next-line prefer-destructuring
      const btns = this.$refs['btns'];
      // let max = this.$refs['maxBtn'];

      if( !cont.classList.contains( '__fullscreen' ) ) {
        cont.classList.add( '__fullscreen' );
        btns.$el.classList.add( '__fullscreen' );
        // max.$el.innerText = 'Restore SpreadSheet'
      } else {
        cont.classList.remove( '__fullscreen' );
        btns.$el.classList.remove( '__fullscreen' );
        // max.$el.innerText = 'Maximise SpreadSheet'
      }
    },
    copySheet() {
      this.copyMode = true;
      this.$refs['table'].classList.add( 'tocopy' );

      setTimeout( () => {
        this.$refs['table'].focus();
        const el = this.$refs['table'];
        const { body } = document;
        let range, sel;
        if ( document.createRange && window.getSelection ) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents( el );
                sel.addRange( range );
            } catch ( e ) {
                range.selectNode( el );
                sel.addRange( range );
            }
        } else if ( body.createTextRange ) {
            range = body.createTextRange();
            range.moveToElementText( el );
            range.select();
            range.execCommand( "Copy" );
        }
        document.execCommand( 'copy' );
        this.$alerts.success( 'Spreadsheet has been copied to clipboard' );
        this.$refs['table'].classList.remove( 'tocopy' );
        this.copyMode = false;
        if( window.getSelection ) {
          window.getSelection().removeAllRanges();
        } else if( document.selection ) {
          document.selection.empty();
        }
      }, 100 );
    },
    cellChanged( cell ) {
      if( this.report.spreadsheets[cell.sheet] ) {
        for( const x in this.report.spreadsheets[cell.sheet].rows ) {
          if( this.report.spreadsheets[cell.sheet].rows.hasOwnProperty( x ) ) {
            const row = this.report.spreadsheets[cell.sheet].rows[x];
            if( row.template._id == cell.row ) {
              for( const y in row.columns ) {
                const col = this.report.spreadsheets[cell.sheet].rows[x].columns[y];
                if( col.id == cell.column ) {
                  this.report.spreadsheets[cell.sheet].rows[x].columns[y].items = cell.items;
                }
              }
            }
        }
        }
      }
    },
    goToIssue( identifier ) {
      // this.$refs.issuefragment.show( identifier );
      this.$refs.issueviewmodal.show( identifier );
    },
  },
  computed: {
    previousSheetsFiltered() {
      if( this.previousSheets ) {
        return this.previousSheets.filter( s => !s.deleted ).filter( s => s.reportVersion._id != this.report.currentVersion._id );
      }

      return this.previousSheets;
    },
  },
  components: {
    // Body: UIBody,
    ContextMenu: UIContextMenu,
    ContextItem: UIContextItem,
    // AriaListBox,
    SpreadsheetCell,
    IssueInstanceModal,
    IssueInstanceViewModal,

    PageModal,
    ComponentModal,

    EditPageModal,
    EditComponentModal,

    SheetSettingsModal,

    // IssueFragment,

    Icon: UIIcon,
  },
};
</script>
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .AddSheet {
    &_Button {
      float: right;
      margin-top: 20px;
    }

    &_Options {
      border: 1px solid $hugr-colours-grey;
      border-radius: 10px;
      &_Option {
        border-bottom: 1px solid $hugr-colours-grey;
        padding: 8px;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        h3 {
          margin: 0;
        }
        p {
          margin-bottom: 0;
        }
        button {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }
    }
  }

  .Spreadsheet {
    &_TopBar {
      position: relative;
      &_Info {

      }
      &_Hint {
        position: absolute;
        left: 500px;
        bottom: 6px;
        font-size: 0.7em;
        width: 350px;
        background: $hugr-colours-grey;
        padding: 5px;
        border-radius: 10px;
        color: #000;
      }
      &_Tabs {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: -3px;
        right: 150px;
        &_Tab {
          display: inline-block;
          vertical-align: top;
          border: 1px solid #cbd2da;
          border-bottom: none;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          margin-left: -1px;
          position: relative;

          &._active {
            background:#cbd2da;
            margin-top: -5px;
            button {
              padding: 8px 8px;
            }
          }

          &_Btn {
            background: transparent;
            border: none;
            padding: 5px 5px;
            cursor: pointer;
          }
          &_Opts {
            padding: 5px !important;
            height: 29px !important;
            width: 25px !important;
          }
        }
      }
      &_Buttons {
        position: absolute;
        right: 50px;
        bottom: 0px;
        &.__fullscreen {
          top: -75px;
          right: 25px;
          z-index: 999999;
        }
      }
    }

    &_Container {
      border-top: 1px solid $hugr-colours-grey;
      height: calc(100vh - 126px);
      overflow: scroll;

      &._hasFooter {
        height: calc( 100vh - 166px );
      }

      &.__fullscreen {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: -66px;
        z-index: 99999;
        background: white;
      }

      &_Table {
        border-collapse: collapse;
        &_Head {
          tr th {
            border-bottom: 2px solid $hugr-colours-grey;
          }
        }
        &_Body {

        }
      }

      &_Overlay {
        position: fixed;
        top: 168px;
        left: 0;
        height: calc(100vh - 190px);
        width: 251px;
        background: #FFF;
        z-index: 99;
        &_Splitter {
          width:51px;
          border-right: 1px solid $hugr-colours-grey;
          height: 100%;
        }

        &._scrolled {
          border-right: 2px solid $hugr-colours-grey;
        }
      }
    }
  }

  .SSHead {
    &_Header {
      background: lighten($hugr-colours-grey, 10%);
      border: 1px solid $hugr-colours-grey;
      text-align: left;
      width: 250px;
      max-width: 250px;
      height: 39px;

      &._topLeft {
        border-right: 2px solid $hugr-colours-grey;
      }

      &_Inner {
        width: 250px;
        max-width: 250px;
        background: lighten($hugr-colours-grey, 10%);
        position: relative;

        z-index: 5000;

        &._scrolled {
          border-bottom: 2px solid $hugr-colours-grey;
          margin-top: -2px;
          border-top: 1px solid $hugr-colours-grey;
        }

        &._focussed {
          border-left: 2px solid $hugr-colours-tertiary;
        }

        &_Head {
          padding: 10px 5px;
          font-weight: normal;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          width: 215px;
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .SSBody {
    &_URLs {
      &_Header {
        background: #FFF;
        border-right: 2px solid $hugr-colours-grey;
      }
      &_Item {
        font-size: 0.8em;
        border: 1px solid $hugr-colours-grey;
        padding: 2px;
        a {
          max-width: 250px;
          width: 250px;
          display: block;
          overflow: hidden;
          span {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 14px);
            overflow: hidden;
          }
          svg {
            top: -3px;
            position: relative;
          }
        }
      }
    }
    &_Section {
      &_Header {
        border: 1px solid $hugr-colours-grey;
        border-right: 2px solid $hugr-colours-grey;

        text-align: left;

        padding: 0;
        &_Inner {
          z-index: 999;
          position: relative;
          background: #FFF;

          border-top: 1px solid $hugr-colours-grey;
          top: -2px;

          &._scrolled {
            border-right: 2px solid $hugr-colours-grey;
          }

          padding: 10px 5px;
          font-weight: normal;
        }
      }
      &_AddColumn {
        background: $hugr-colours-grey;
        width: 250px;
        max-width: 250px;
        vertical-align: top;
        height: calc(100vh - 190px);
        // position: fixed;
        &_Inner {
          width: 250px;
          max-width: 250px;
          padding: 20px;
          box-sizing: border-box;
          margin-top: 30vh;

          position: relative;

          &_Btns {
            position: absolute;
            left: 15px;
            width: 220px;
            button {
              width: 100%;
              margin-top:20px;
              text-align: center;
              border: 1px solid $hugr-colours-primary !important;
            }
          }
        }
      }
      &_Fill {
        background: $hugr-colours-grey;
        border: 1px solid $hugr-colours-grey;
      }
    }
    &_Row {
      &_Header {
        border: 1px solid $hugr-colours-grey;
        border-right: 2px solid $hugr-colours-grey;

        text-align: left;
        font-weight: normal;
        font-size: 0.8em;

        padding: 0;
        vertical-align: top;

        &_Inner {
          z-index: 999;
          position: relative;
          background: #FFF;
          max-width: 250px;
          border-top: 1px solid $hugr-colours-grey;
          top: -1px;

          &._scrolled {
            border-right: 2px solid $hugr-colours-grey;
          }

          &_Index {
            display: inline-block;
            vertical-align: top;
            width: 20%;

            padding: 10px 5px;
            box-sizing: border-box;
            text-align: right;
          }
          &_Contents {
            display: inline-block;
            vertical-align: top;
            width: 80%;

            padding: 10px 5px;
            box-sizing: border-box;
            border-left: 1px solid $hugr-colours-grey;
          }
        }
      }
    }
  }
  table.tocopy {
    button {
      display: none;
    }
    .SSBody_Row_Header_Inner_Index {
      display: none;
    }
    .SSBody_Section_AddColumn {
      display: none;
    }
    .SSBody_Section_AddColumn_Inner {
      display: none;
    }
    .SSHead_Header_End {
      display: none;
    }
  }

  .TabOpts {
    z-index: 9999999;
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid $hugr-colours-grey;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 100%;
    right: 0;

    &._offset {
      margin-top: -3px;
    }

    &_Opt {
      width: 100%;
      &_Btn {
        width: 100%;
      }
    }
  }

  // .hint {
  //   position: absolute;
  //   top: 27px;
  //   left: 400px;
  //   z-index: 99999999999;
  //   color: white;
  //   background: black;
  //   padding: 5px;
  //   border-radius: 5px;
  //   max-width: 50%;
  // }

  ._darkMode .AddSheet {

  }

  ._darkMode .Spreadsheet {
    &_TopBar {
      &_Tabs {
        &_Tab {
          &_Btn {
            color: $hugr-colours-grey;
          }
          &._active {
            .Spreadsheet_TopBar_Tabs_Tab_Btn {
              color: #000;
            }
          }
        }
      }
    }
    &_Container {
      &_Table {
        &_Head {
          tr {
            th {
              border-bottom: 2px solid lighten($hugr-colours-primary, 10%);
            }
          }
        }
      }
      &_Overlay {
        background: $hugr-colours-primary;
        &_Splitter {
          border-right: 1px solid lighten($hugr-colours-primary, 10%);
        }

        &._scrolled {
          border-right: 2px solid lighten($hugr-colours-primary, 10%);
        }
      }
    }
  }

  ._darkMode .TabOpts {
    background: $hugr-colours-primary;
  }

  ._darkMode .SSHead {
    &_Header {
      background: lighten($hugr-colours-primary, 10%);
      border: 1px solid lighten($hugr-colours-primary, 10%);
      &_Inner {
        background: darken($hugr-colours-primary, 10%);
        &._scrolled {
          border-top: 1px solid lighten($hugr-colours-primary, 10%);
          border-bottom: 2px solid lighten($hugr-colours-primary, 10%);
        }
      }
    }
  }

  ._darkMode .SSBody {
    &_URLs {
      &_Header {
        background: $hugr-colours-primary;
        border-right: 2px solid lighten($hugr-colours-primary, 10%);
      }
      &_Item {
        border: 1px solid lighten($hugr-colours-primary, 10%);
      }
    }
    &_Section {
      &_Header {
        border: 1px solid lighten($hugr-colours-primary, 10%);
        border-right: 2px solid lighten($hugr-colours-primary, 10%);
        &_Inner {
          background: darken($hugr-colours-primary, 10%);
          border-top: 1px solid lighten($hugr-colours-primary, 10%);
          &._scrolled {
            border-right: 2px solid lighten($hugr-colours-primary, 10%);
          }
        }
      }
      &_Fill {
        border-bottom: 1px solid lighten($hugr-colours-primary, 10%);
        border-right: 1px solid lighten($hugr-colours-primary, 10%);
        background: darken($hugr-colours-primary, 10%);
      }
      &_AddColumn {
        background: darken($hugr-colours-primary, 10%);
      }
    }

    &_Row {
      &_Header {
        border: 1px solid lighten($hugr-colours-primary, 10%);
        border-right: 2px solid lighten($hugr-colours-primary, 10%);
        &_Inner {
          background: $hugr-colours-primary;
          border-top: 1px solid lighten($hugr-colours-primary, 10%);

          &_Contents {
            border-left: 1px solid lighten($hugr-colours-primary, 10%);
          }

          &._scrolled {
            border-right: 2px solid lighten($hugr-colours-primary, 10%);
          }
        }
      }
    }
  }
</style>
