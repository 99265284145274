<template>
  <Modal
    ref="modal"
    :title="modalTitle"
    :subtitle="modalSubTitle"

    :showActions="false"

    :headBack="showingSolution"
    headBackText="Back to issue instance"
    @headBack="() => { showingSolution = false }"

    @close="reset()"
    class="ViewIssueModal"
    :fix="true">
    <div class="ViewIssueModal_Solution" v-if="showingSolution&&solution">
      <Table>
        <tr>
          <th v-translate>Overview</th>
          <td>
            <vue-markdown :key="solution.identifier+'-overview'" v-highlight :html="false">{{ solution.overview }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Incorrect code example</th>
          <td>
            <vue-markdown :key="solution.identifier+'-code'" v-highlight :html="false">{{ solution.code }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Suggested code example</th>
          <td>
            <vue-markdown :key="solution.identifier+'-fix'" v-highlight :html="false">{{ solution.fix }}</vue-markdown>
          </td>
        </tr>
        <tr v-if="solution.issueTemplates.length">
          <th v-translate>Associated issues</th>
            <td>
              <ul>
                <li v-for="issueTemplate in solution.issueTemplates.slice(0, 3)" :key="issueTemplate._id">[{{ issueTemplate.identifier }}] {{ issueTemplate.title  }}</li>
              </ul>
            </td>
          </tr>
        <tr v-if="solution.metadata.length > 0 && solution.metadata[0]!=''">
          <th v-translate>Associated metadata tags</th>
          <td>
              <span v-bind:key="solution.identifier+ '-tag-' + key" class="pill" v-for="(tag,key) in solution.metadata">
                {{ solution.metadata[key] }}
              </span>
          </td>
        </tr>
      </Table>
    </div>
    <div class="ViewIssueModal_Inner __grid" v-else-if="issue">
      <div class="ViewIssueModal_Inner_Left __grid_column_8" v-if="changeTemplate">
        <AriaSearchSelect ref="issueTemplateSelect" :label="$gettext('Issue Template')" :dropdown="true" :gqlOptions="['ISSUETEMPLATES_BY_REPORT', issue.reportVersion._id]" @selected="setIssueTemplate" :validation="['not-empty']"/>
        <FormInput idRoot="issue_" :label="$gettext('Instance Identifier')" v-model="newIdentifier" disabled/>

        <Notice size="micro">{{$gettext('All issue details will be copied to a new issue template, the existing issue will be marked as closed.')}}</Notice>
        <div class="ViewIssueModal_Inner_Left_Actions">
          <Button type="secondary" size="small" @click="doMutateIssue" :disabled="mutating">{{ mutating?'Changing...':'Save' }}</Button>
          &nbsp;
          <Button size="small" @click="cancelTemplateChange()">Cancel</Button>
        </div>
      </div>
      <div class="ViewIssueModal_Inner_Left __grid_column_8" v-else>
        <div class="ViewIssueModal_Inner_Left_Buttons" v-if="!edit">
          <Button size="micro" type="secondary" :icon="['solid', 'pen']" @click="doEdit()">Edit issue</Button>
          <Button size="micro" type="tertiary" :icon="['solid', 'sliders']" @click="doChangeTemplate()">Change issue template</Button>

          <span class="ViewIssueModal_Inner_Left_Buttons_Divider"></span>

          <Button size="micro" type="border" :icon="['solid', 'chevron-left']" v-if="issue.prevInstance" @click="show(issue.prevInstance.identifier)">Previous issue</Button>
          <Button size="micro" type="border" :icon="['solid', 'chevron-right', 'after']" v-if="issue.nextInstance" @click="show(issue.nextInstance.identifier)">Next issue</Button>
        </div>

        <Loader v-if="loading" />
        <template v-else>
          <template v-if="!edit">
            <h4>Description</h4>
            <template v-if="issue.reason">
              <vue-markdown :key="issue.identifier+'-description'" v-highlight :html="false">{{ issue.reason }}</vue-markdown>
            </template>
            <template v-else>None added</template>
          </template>
          <TextArea v-else idRoot="issue_" :label="$gettext('Description')" v-model="tmpIssue.reason" />
          
          <template v-if="!edit">
            <h4>Solution(s)</h4>
            <ul v-if="issue.solutions.length" class="ViewIssueModal_Inner_Left_Solutions">
              <li v-for="solution in issue.solutions" :key="solution.identifier">
                <Button type="link" @click="showSolution( solution._id )">{{ solution.identifier }}: {{ solution.title }}</Button>
              </li>
            </ul>
            <p v-else v-translate>None added</p>
          </template>
          <AriaSearchSelect v-else ref="solutionselect" :label="$gettext('Solutions')" :dropdown="true" :multiple="true" idRoot="issue_" :gqlOptions="['SOLUTIONS']" @selected="setSolutions" :priority="issue.template.solutions.map( s => s._id )" :default="issue.solutions.map( s => s._id ).join( ',' )"/>

          <h4>Attachments</h4>
          <Gallery v-model="issue.evidence" :editable="edit" :instance="issue._id"/>

          <div v-if="!edit">
            <h4>Comments</h4>
            <Comments
              :refresh="commentKey"
              :reference="issue._id"
              :team="issue.reportVersion.report.team?issue.reportVersion.report.team._id:( issue.reportVersion.report.portfolio ? issue.reportVersion.report.portfolio.teams[0]._id : false )"
              :fileUploadEnabled="hasPermission('Files','Upload')"
              :reportID="issue.reportVersion.report._id"/>
          </div>

          <div v-if="edit" class="IssueFragment_Grid_Particulars_EditActions">
            <Button size="small" @click="cancelEdit()">Cancel</Button>
            <Button size="small" type="secondary" @click="saveEdit()">Save</Button>
          </div>
        </template>
      </div>
      <div class="ViewIssueModal_Inner_Right __grid_column_4">
        <button class="ViewIssueModal_Inner_Right_KeyIssueBtn" :title="issue.isAKeyIssueInstance?'Is a key issue':'Make a key issue'" :aria-label="issue.isAKeyIssueInstance?'Is a key issue':'Make a key issue'" @click="changeKeyIssue( !issue.isAKeyIssueInstance )">
          <Icon :type="issue.isAKeyIssueInstance?'solid':'regular'" icon="star" />
        </button>
        <dl class="ViewIssueModal_Inner_Right_List">
          <div>
            <dt>Issue Status</dt>
            <dd>
              <span v-if="issue.reportVersion.published">
                <AriaListBox v-model="issue.status" idRoot="instance_" label="Status" :labelVisible="false" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                  />
              </span>
              <span v-else>
                <AriaListBox idRoot="instance_" :label="$gettext('Status')" :labelVisible="false" v-model="issue.status" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'closed-removed': $gettext('Closed - Removed'),
                    'closed-fixed': $gettext('Closed - Fixed'),
                    'closed-disproportionate': $gettext('Closed - Disproportionate Burden'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'closed-removed': $gettext('Component with issue has been removed or mitigated.'),
                    'closed-fixed': $gettext('Issue has been fixed.'),
                    'closed-disproportionate': $gettext('Item has been closed due to disproportionate burden'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                />
              </span>
            </dd>
          </div>
          <span class="ViewIssueModal_Inner_Right_List_Divider"></span>
          <div class="ViewIssueModal_Inner_Right_List_Details">
            <details>
              <summary>
                <Icon type="solid" icon="info-circle" />
                &nbsp;
                Learn more about this type of issue
                <span class="ViewIssueModal_Inner_Right_List_Details_Marker">
                  <Icon class="ViewIssueModal_Inner_Right_List_Details_Marker_Down" type="solid" icon="chevron-down" />
                  <Icon class="ViewIssueModal_Inner_Right_List_Details_Marker_Up" type="solid" icon="chevron-up" />
                </span>
              </summary>
              <div class="ViewIssueModal_Inner_Right_List_Details_Inner">
                <div>
                  <dt>Description</dt>
                  <dd>{{ issue.template.description }}</dd>
                </div>
                <div>
                  <dt>WCAG Reference</dt>
                  <dd>
                    <span v-if="issue.template.criteria.length==1">{{ issue.template.criteria[0].criterion }} {{ issue.template.criteria[0].title }} (Level {{ issue.template.criteria[0].level }}) [{{ issue.template.criteria[0].identifier }}]</span>
                    <ul v-else>
                      <li v-for="criterion in issue.template.criteria" :key="criterion._id">{{ criterion.criterion }} {{ criterion.title }} ( Level {{ criterion.level }}) [{{ criterion.identifier }}]</li>
                    </ul>
                  </dd>
                </div>
                <div>
                  <dt>Affected Users</dt>
                  <dd>{{ issue.template.affected.join( ', ') }}</dd>
                </div>
                <div>
                  <dt>Impact on Users</dt>
                  <dd>{{ issue.template.impact }}</dd>
                </div>
              </div>
            </details>
          </div>
          <div v-if="issue.reportVersion.report.team || issue.reportVersion.report.portfolio">
            <dt>Assignee</dt>
            <dd>
              <AriaListBox idRoot="instance_" :label="$gettext('Assignee')" :labelVisible="false" v-model="assignee" @change="assigneeChange"
                  :options="assignOpts"
                />
            </dd>
          </div>
          <div>
            <dt>QA Status</dt>
            <dd>
              <AriaListBox v-if="!issue.reportVersion.published" idRoot="instance_" :label="$gettext('QA Status')" :labelVisible="false" v-model="issue.flag" @change="flagChange"
                :options="{
                  'unchecked': $gettext('Unchecked'),
                  'to-check': $gettext('To Check'),
                  'checked': $gettext('Checked'),
                  'change-required': $gettext('Change Required'),
                  'second-opinion': $gettext('Second Opinion')
                }"
                :extra="{
                  'unchecked': $gettext(`Issue hasn't been checked`),
                  'to-check': $gettext('Issue needs to be checked'),
                  'checked': $gettext('Issue has been checked and confirmed'),
                  'change-required': $gettext('A change is required before the issue can be confirmed'),
                  'second-opinion': $gettext('Issue has been checked but a second opinion is required')
                }"
              />
              <span v-else>{{ issue.flag }}</span>
            </dd>
          </div>
          <div class="_inline">
            <dt>Severity</dt>
            <dd>
              <Pill :text="['Advisory', 'Low', 'Medium', 'High', 'Critical'][ issue.template.severity ]" size="small"/>
            </dd>
          </div>
          <div class="_inline">
            <dt>Priority</dt>
            <dd>
              <Pill :text="['Lowest', 'Low', 'Medium', 'High', 'Highest'][ issue.priority ]" size="small"/>
            </dd>
          </div>
          <div aria-live="polite">
            <dt>Found On</dt>
            <dd class="ViewIssueModal_Inner_Right_List_Pages">
              <template v-if="issue.component">
                <Pill size="small" :text="issue.component.identifier" :icon="['solid', 'puzzle-piece']"/>
                <Pill size="small" v-for="page in issue.component.pages" :key="page._id" :text="page.name" :icon="['regular', 'file']" />
              </template>
              <template v-else-if="issue.page">
                <Pill size="small" :text="issue.page.name" :dismissable="issue.others.length&&!issue.reportVersion.published" :dismissAlt="`Remove issue from ${issue.page.name}`" @dismiss="removePrimaryPage()"/>
                <Pill v-for="other in issue.others" :key="other._id" size="small" :text="other.page.name" :dismissable="!issue.reportVersion.published" :dismissAlt="`Remove issue from ${other.page.name}`" @dismiss="removeGroupPage(other._id)"/>
                <Button v-if="!doAddPage&&!issue.reportVersion.published" type="icon" size="micro" :icon="['solid', 'plus']" @click.prevent="goToAddPage()" title="Add issue to a page">Add issue to a page</Button>
                <div class="ViewIssueModal_Inner_Right_List_Pages_AddPage"  v-if="doAddPage">
                  <AriaSearchSelectLegacy ref="addpage" :label="$gettext('Add a Page')" :icon="['regular', 'file']" :iconOnly="true" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', issue.reportVersion._id]"  @selected="addGroupPage"/>
                </div>
              </template>
            </dd>
          </div>
          <div class="_inline">
            <dt>Reported by</dt>
            <dd>
              <Avatar :uid="issue.reporter._id" type="round" />
              &nbsp;
              <span>{{ issue.reporter.name }}</span>
            </dd>
          </div>
          <div class="_inline">
            <dt>Reported on</dt>
            <dd>{{ format( new Date( issue.timestamp ), 'P' ) }}</dd>
          </div>
          <!-- <div>
            <dt>Last updated</dt>
            <dd></dd>
          </div> -->
        </dl>
      </div>
    </div>
  </Modal>
</template>

<script setup>
  import { ref, computed, inject, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';
  import { useQuery, useMutation } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import { format } from 'date-fns';

  import Table from '@/components/UI/Table';

  import AriaListBox from '@/components/Aria/ListBox.vue';
  import TextArea from '@/components/Form/TextArea';
  import FormInput from '@/components/Form/Input';
  import AriaSearchSelect from '@/components/Aria/SearchSelect3';
  import AriaSearchSelectLegacy from '@/components/Aria/SearchSelect2';

  import Gallery from '@/components/UI/Gallery';
  import Pill from '@/components/UI/Pill.vue';
  import Avatar from '@/components/Helpers/Avatar';
  import Comments from '@/components/Comments/Index';

  const emit = defineEmits( [ 'resolved', 'closed', 'mutated' ] );

  const app = getCurrentInstance();

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );
  const user = computed( () => store.getters.user );

  const modal = ref( null );
  const issueIdentifier = ref( null );
  const doQuery = ref( false );
  const edit = ref( false );
  const commentKey = ref( 0 );
  const doAddPage = ref( false );
  const addpage = ref( null );

  const changeTemplate = ref( false );
  const issueTemplateSelect = ref( null );

  const doChangeTemplate = () => {
    changeTemplate.value = true;
    setTimeout( () => {
      issueTemplateSelect.value.focus();
    }, 100 );
  };

  const cancelTemplateChange = () => {
    changeTemplate.value = false;
  };

  const goToAddPage = () => {
    doAddPage.value = true;
    setTimeout( () => {
      addpage.value.focus();
    }, 100 );
  };

  const { loading, onResult, refetch } = useQuery( gql`
    query IssueInstance($identifier: String!) {
      issue: IssueInstance(identifier: $identifier) {
        _id
        identifier
        evidence
        reason
        priority
        status
        flag
        isAKeyIssueInstance
        timestamp

        nextInstance {
          _id,
          identifier
        }
        prevInstance {
          _id,
          identifier
        }

        reporter {
          _id,
          name,
          email
        }

        assignee {
          _id,
          name,
          email
        }

        page {
          _id
          name
          host
          path
          progress
          isCompliant
          issues {
            _id
          }
        }
        component {
          _id
          identifier,
          pages {
            _id
            name
            progress
            isCompliant
            issues {
              _id
            }
          }
        }

        others {
          _id
          page {
            _id
            name
            host
            path
            progress
            isCompliant
            issues {
              _id
            }
          }
        }

        solutions {
          _id,
          identifier,
          title
        }
        
        template {
          _id
          identifier
          title
          description
          affected
          impact
          severity

          criteria {
            _id
            criterion
            identifier
            title
            level
          }

          solutions {
            _id
          }
        }

        reportVersion {
          _id
          version
          published
          report {
            _id
            identifier

            owner {
              _id,
              name,
              email
            }
            collaborators {
              _id,
              name,
              email
            }
            team {
              _id
              users {
                _id,
                name,
                email
              }
            }
            portfolio {
              _id
              teams {
                _id
                users {
                  _id,
                  name,
                  email
                }
              }
            }
          }
        }
      }
    }
  `,
  {
    identifier: issueIdentifier,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const issue = ref( null );

  const tmpIssue = ref( null );

  const doEdit = () => {
    tmpIssue.value = { ...issue.value };
    setTimeout( () => {
      edit.value = true;
    }, 100 );
  };

  const cancelEdit = () => {
    tmpIssue.value = null;
    edit.value = false;
  };

  const { mutate: updateIssueInstanceSolutions } = useMutation(
    gql`
    mutation updateIssueInstanceSolutions($id: ObjectID!, $solutions: [ObjectID]!) {
      instance: updateIssueInstanceSolutions(id: $id, solutions: $solutions) {
        _id,
        solutions {
          _id,
          identifier,
          title
        }
      }
    }
  ` );

  const { mutate: updateIssueInstanceParticulars } = useMutation(
    gql`
    mutation updateIssueInstanceParticulars($id: ObjectID!, $evidence: [String]!, $reason: String!) {
      instance: updateIssueInstanceParticulars(id: $id, evidence: $evidence, reason: $reason) {
        _id,
        reason,
      }
    }
  ` );

  const setSolutions = solutions => {
    tmpIssue.value.solutions = solutions.map( s => { return { _id: s } } );
  };

  const { mutate: setIssueInstancePriority } = useMutation(
    gql`
    mutation setIssueInstancePriority($id: ObjectID!, $priority: Int!) {
      instance: setIssueInstancePriority(id: $id, priority: $priority) {
        priority
      }
    }
  ` );

  const saveEdit = () => {
    updateIssueInstanceParticulars( {
      id: issue.value._id,
      evidence: [], //old
      reason: tmpIssue.value.reason,
    } ).then( res => {
      issue.value.reason = res.data.instance.reason;
      alerts.success( 'Particulars updated', `Issue instance particulars have been updated` );
    } ).catch( () => {
      alerts.error( `Oops. We can't update that at the moment :/`, `There's an issue on the backend.` );
    } );

    updateIssueInstanceSolutions( {
      id: issue.value._id,
      solutions: tmpIssue.value.solutions.map( solution => solution._id ),
    } ).then( res => {
      issue.value.solutions = res.data.instance.solutions;
      alerts.success( 'Solutions updated', `Issue instance solutions have been updated` );
    } ).catch( () => {
      alerts.error( `Sorry, we couldn't update the solution :/`, `` );
    } );
    
    const priority = parseInt( tmpIssue.value.priority );
    setIssueInstancePriority( {
      id: issue.value._id,
      priority,
    } ).then( res => {
      issue.value.priority = res.data.instance.priority;
      alerts.success( 'Priority updated', `Issue instance priority has been updated` );
    } ).catch( () => {
      alerts.error( `Issue priority not changed`, `There's a bug and you've found it` );
    } );

    setTimeout( () => {
      edit.value = false;
    }, 100 );
  };

  const assignOpts = computed( () => {
    const res = {
      0: 'Unassigned',
    };

    if( issue.value.reportVersion.report.team ) {
      for( const user of issue.value.reportVersion.report.team.users ) {
        res[user._id] = `${user.name} (${user.email})`;
      }
    } else if( issue.value.reportVersion.report.portfolio ) {
      for( const team of issue.value.reportVersion.report.portfolio.teams ) {
        for( const user of team.users ) {
          if( Object.keys( res ).indexOf( user._id ) < 0 ) {
            res[user._id] = `${user.name} (${user.email})`;
          }
        }
      }
    }

    return res;
  } );

  const assignee = computed( {
    get() {
      if( issue.value.assignee ) {
        return issue.value.assignee._id;
      }

        return 0;

    },
    set( v ) {
      if( v == 0 ) {
        issue.value.assignee = null;
      } else if( issue.value.reportVersion.report.team ) {
          for( const user of issue.value.reportVersion.report.team.users ) {
            if( user._id == v ) {
              issue.value.assignee = user;
            }
          }
        } else if( issue.value.reportVersion.report.portfolio ) {
          for( const team of issue.value.reportVersion.report.portfolio.teams ) {
            for( const user of team.users ) {
              if( user._id == v ) {
                issue.value.assignee = user;
              }
            }
          }
        }
    },
  } );

  const { mutate: addComment } = useMutation(
    gql`
    mutation addComment($comment: CommentInput!) {
      comment: addComment(comment: $comment) {
        _id
      }
    }
  ` );

  const { mutate: setIssueInstanceStatus } = useMutation(
    gql`
    mutation setIssueInstanceStatus($id: ObjectID!, $status: String!) {
      instance: setIssueInstanceStatus(id: $id, status: $status) {
        oldStatus
        issue {
          status
        }
      }
    }
  ` );

  const statusChange = ( e, to ) => {
    const status = to || issue.value.status;

    setIssueInstanceStatus( {
      id: issue.value._id,
      status,
    } ).then( res => {
      const { status } = res.data.instance.issue;
      alerts.success( 'Status changed', `Issue instance status has been changed to ${issue.value.status}` );

      const positive = [ 'closed-fixed' ];
      const negative = [ 'reported' ];
      const indifferent = [ 'closed-removed' ];

      if ( [ ...positive, ...indifferent ].indexOf( status ) >= 0 ) emit( 'resolved', issue.value.identifier );

      if ( [ ...positive, ...negative, ...indifferent ].indexOf( status ) >= 0 ) {
        const prefilledMessage = () => {
          if ( positive.includes( status ) ) return 'Fixed: ';
          if ( negative.includes( status ) ) return 'Not Fixed: ';
          if ( indifferent.includes( status ) ) return '';

          return '';
        };

        confirm.input( 'Add a comment about this change?', prefilledMessage() ).then( ( [ yesNo, commentToAdd ] ) => {
          if ( yesNo ) {
            addComment( {
              comment: {
                reference: issue.value._id,
                from: user.value.id,
                contents: commentToAdd,
                edited: false,
              },
            } ).then( () => {
              alerts.success( 'Comment posted!', `Your comment has successfully been posted.` );
              commentKey.value += 1;
            } ).catch( () => {
              alerts.error( `The comment can't be added`, `It's not you it's us. We're still in Alpha so please bear with us and <a target="_blank" href="https://hugr.community/c/Post-any-questions-were-here-to-help-and-hopefully-the-answers-will-help-others-too/bugs/">report a bug</a>` );
            } );
          }
        } );
      }
    } ).catch( () => {
      alerts.error( `Issue status not changed`, `There's a bug and you've found it` );
      //TODO this should change it back
    } );
  };

  const { mutate: setIssueInstanceAssignee } = useMutation(
    gql`
    mutation setIssueInstanceAssignee($id: ObjectID!, $assignee: ObjectID!) {
      instance: setIssueInstanceAssignee(id: $id, assignee: $assignee) {
        _id
        assignee {
          _id,
          name,
        }
      }
    }
  ` );

  const { mutate: setIssueInstanceUnAssigned } = useMutation(
    gql`
    mutation setIssueInstanceUnAssigned($id: ObjectID!) {
      instance: setIssueInstanceUnAssigned(id: $id) {
        _id
      }
    }
  ` );

  const assigneeChange = ( e, to ) => {
    const myAssignee = to || assignee.value;
    if( myAssignee != 0 ) {
      setIssueInstanceAssignee( {
        id: issue.value._id,
        assignee: myAssignee,
      } ).then( res => {
        alerts.success( 'Assignee changed', `Issue instance has been assigned to ${res.data.instance.assignee.name}` );
      } ).catch( () => {
        alerts.error( `Issue status not assigned`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    } else {
      setIssueInstanceUnAssigned( {
        id: issue.value._id,
      } ).then( () => {
        alerts.success( 'Assignee changed', `Issue instance has been unassigned` );
      } ).catch( () => {
        alerts.error( `Issue status has not been unassigned`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    }
  };

  const { mutate: setIssueInstanceFlag } = useMutation(
    gql`
    mutation setIssueInstanceFlag($id: ObjectID!, $flag: String!) {
      instance: setIssueInstanceFlag(id: $id, flag: $flag) {
        _id
      }
    }
  ` );

  const flagChange = ( e, to ) => {
    const flag = to || issue.value.flag;
    setIssueInstanceFlag( {
      id: issue.value._id,
      flag,
    } ).then( () => {
      alerts.success( 'QA Status changed', `Issue instance QA status has been changed to ${issue.value.flag}` );
    } ).catch( () => {
      alerts.error( `QA status not changed`, `There's a bug and you've found it` );
      //TODO this should change it back
    } );
  };

  const { mutate: setKeyIssue } = useMutation(
    gql`
    mutation setIssueInstanceKeyIssue($id: ObjectID!, $value: Boolean!) {
      result: setIssueInstanceKeyIssue(id: $id, value: $value)
    }
  ` );

  const changeKeyIssue = value => {
    setKeyIssue( {
      id: issue.value._id,
      value,
    } ).then( () => {
      issue.value.isAKeyIssueInstance = value;
      if( issue.value.isAKeyIssueInstance ) {
        alerts.success( 'Set as key issue', `Instance has been set as a key issue` );
      } else {
        alerts.success( 'Removed from key issues', `Instance has been removed from key issues` );
      }
    } ).catch( () => {
      alerts.error( `Couldn't set as key issue`, `Something went wrong, please try again.` );
    } );
  };

  const { mutate: addIssueInstancePage } = useMutation(
    gql`
    mutation addIssueInstancePage($parent: ObjectID!, $page: ObjectID!) {
      instance: addIssueInstancePage(parent: $parent, page: $page) {
        _id,
        others {
          _id
          page {
            _id
            name
          }
        }
      }
    }
  ` );

  const addGroupPage = ( page, display ) => {
    if( issue.value.page._id == page || issue.value.others.map( instance => instance.page._id ).indexOf( page ) >= 0 ) {
      alerts.warn( 'Issue already on this page', `This issue is already associated with this page, it can't be added again.` );
    } else {
      addIssueInstancePage( {
        parent: issue.value._id,
        page,
      } ).then( res => {
        issue.value.others = res.data.instance.others;
        alerts.success( 'Page added', `Instance has been added to page ${display}` );
      } ).catch( () => {
        alerts.error( `Couldn't add the page`, `Something went wrong, please try again.` );
      } );
    }
  };

  const { mutate: removeIssueInstancePage } = useMutation(
    gql`
    mutation removeIssueInstancePage($parent: ObjectID!, $id: ObjectID!) {
      instance: removeIssueInstancePage(parent: $parent, id: $id) {
        _id,
        others {
          _id
          page {
            _id
            name
          }
        }
      }
    }
  ` );

  const removeGroupPage = id => {
    removeIssueInstancePage( {
      parent: issue.value._id,
      id,
    } ).then( res => {
      issue.value.others = res.data.instance.others;
      alerts.success( 'Page removed', `Instance has been removed from page` );
    } ).catch( err => {
      alerts.error( `Couldn't remove the page`, `Something went wrong, please try again.` );
    } );
  };
  
  const { mutate: changeIssueInstancePage } = useMutation(
    gql`
    mutation changeIssueInstancePage($issue: ObjectID!) {
      instance: changeIssueInstancePage(issue: $issue) {
        _id,
        page {
          _id
          name
        }
        others {
          _id
          page {
            _id
            name
          }
        }
      }
    }
  ` );

  const removePrimaryPage = () => {
    changeIssueInstancePage( {
      issue: issue.value._id,
    } ).then( res => {
      issue.value.page = res.data.instance.page;
      issue.value.others = res.data.instance.others;
      alerts.success( 'Page removed', `Instance has been removed from page` );
    } ).catch( err => {
      alerts.error( `Couldn't remove the page`, `Something went wrong, please try again.` );
    } );
  };

  const mutating = ref( false );

  const { mutate: mutateIssue } = useMutation(
    gql`
    mutation mutateIssue($issue: ObjectID!, $template: ObjectID!, $identifier: String!) {
      instance: mutateIssue(issue: $issue, template: $template, identifier: $identifier) {
        _id,
        identifier
      }
    }
  ` );

  const lastUrl = ref( false );
  
  const show = ( identifier, withEdit = false ) => {
    issueIdentifier.value = identifier;
    doQuery.value = true;
    onResult( ( { data } ) => {
      issue.value = data.issue;
      // reportVersion.value = data.component.reportVersion._id;
      modal.value.show();
      if( withEdit ) doEdit();
      if( !lastUrl.value ) lastUrl.value = window.location.href;
      window.history.pushState( {}, null, `/${app.appContext.config.globalProperties.$hugrConfig.reportRouterReplacement}s/${issue.value.reportVersion.report.identifier}/${issue.value.reportVersion.version}/issues/${identifier}` );
      doQuery.value = false;
    } );
  };

  // const issueTemplateSelect = ref( null );
  const newIssueTemplate = ref( null );
  const doIdentifierQuery = ref( false );
  const newIdentifier = ref( null );
  const reportVersion = ref( null );
  const oldIdentifier = ref( null );

  const { onResult: onIdentifierResult, onError: onIdentifierError } = useQuery( gql`
    query newInstanceIdentifier($rv: ObjectID!, $tmp: ObjectID!) {
      identifier: newInstanceIdentifier(reportVersion: $rv, template: $tmp)
    }
  `,
  {
    rv: reportVersion,
    tmp: newIssueTemplate,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doIdentifierQuery,
  } );

  const setIssueTemplate = ( issueTemplate, display ) => {
    issueTemplateSelect.value.select( issueTemplate, display );
    newIssueTemplate.value = issueTemplate;
    reportVersion.value = issue.value.reportVersion._id;
    doIdentifierQuery.value = true;
    onIdentifierResult( ( { data } ) => {
      newIdentifier.value = data.identifier;
      doIdentifierQuery.value = false;
    } );
  };

  onIdentifierError( error => alerts.generic( error ) );

  const doMutateIssue = () => {
    mutating.value = true;
    oldIdentifier.value = issue.value.identifier;
    mutateIssue( {
      issue: issue.value._id,
      template: newIssueTemplate.value,
      identifier: newIdentifier.value,
    } ).then( res => {
      alerts.success( 'Issue template changed' );
      mutating.value = false;
      changeTemplate.value = false;
      show( res.data.instance.identifier );
      emit( 'mutated', oldIdentifier.value, newIdentifier.value );
    } ).catch( error => {
      mutating.value = false;
      alerts.generic( error );
    } );
  };

  const reset = () => {
    window.history.pushState( {}, null, lastUrl.value );
    edit.value = false;
    changeTemplate.value = false;
    emit( 'closed' );
    modal.value.reset();
  };

  const solutionId = ref( null );
  const doSolutionQuery = ref( false );
  const solution = ref( null );
  const showingSolution = ref( false );

  const { loading: loadingSolution, onResult: onSolutionResult, refetch: refetchSolution, onError: onSolutionError } = useQuery( gql`
    query Solution($id: ObjectID!) {
      solution: Solution(id: $id) {
        _id,
        identifier,
        title,
        overview,
        code,
        fix,
        metadata,
        issueTemplates {
          _id
          identifier
          title
        }
        owner {
          _id,
          name
        }
        team {
          _id
          name
        }
        private
        locked
        canEdit
      }
    }
  `,
  {
    id: solutionId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doSolutionQuery,
  } );

  const showSolution = id => {
    solutionId.value = id;
    doSolutionQuery.value = true;
    if( solution.value && solution.value._id == id ) refetchSolution();
    onSolutionResult( ( { data } ) => {
      solution.value = data.solution;
      showingSolution.value = true;
    } );
  };

  onSolutionError( error => alerts.generic( error ) );

  const modalTitle = computed( () => {
    if( showingSolution.value ) return `Solution ${solution.value.identifier}: ${solution.value.title}`;

    return `Instance ${issueIdentifier.value}`;
  } );

  const modalSubTitle = computed( () => {
    if( !showingSolution.value && issue.value ) return `of issue ${issue.value.template.identifier}: ${issue.value.template.title}`;

    return '';
  } );

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';
  .ViewIssueModal {
    &_Inner {
      height: 100%;
      overflow: hidden;
      &_Left {
        overflow-y: auto;
        overflow-x: hidden;
        @include vertical-scroll;
        h4 {
          font-weight: bold;
          font-size: 0.9em;
          margin: 0;
          margin-top: 8px;
        }
        p {
          margin: 0;
        }
        &_Buttons {
          margin-bottom: 8px;
          button {
            margin-right: 8px;
          }
          &_Divider {
            border-right: 1px solid;
            margin-right: 8px;
          }
        }

        &_Solutions {
          margin: 0;
          padding-left: 0;
          list-style: none;
          li {
            padding: 0;
          }
        }
      }
      &_Right {
        overflow-y: auto;
        @include vertical-scroll;

        &_KeyIssueBtn {
          position: absolute;
          top: 54px;
          right: 16px;

          background: transparent;
          border: 1px dashed;
          border-radius: 16px;
          width: 28px;
          height: 28px;
          cursor: pointer;

          &:hover, &:focus {
            background: lighten( $dig-blue, 60% );
          }
        }
        &_List {
          > div {
            margin-bottom: 8px;
            dt {
              font-weight: 600;
              font-size: 0.9em;
              margin-bottom: 4px;
            }
            dd {
              margin-left: 0;
            }
          }
          &_Divider {
            display: block;
            border-bottom: 1px solid $hugr-colours-grey;
            margin: 14px 0 16px 0;
          }
          &_Details {
            details {
              background: $hugr-colours-input-surface;
              padding: 8px;
              summary {
                font-size: 0.8em;
                font-weight: bold;
                color: $hugr-colours-primary;
                &::marker {
                  display: none;
                  content: '';
                }
              }
              
            }
            &_Marker {
              &_Up { display: none; }
              float: right;
            }
            details[open] > summary {
              .ViewIssueModal_Inner_Right_List_Details_Marker_Down {
                display: none;
              }
              .ViewIssueModal_Inner_Right_List_Details_Marker_Up {
                display: inline-block;
              }
            }
            &_Inner {
              font-size: 0.9em;
              dt { margin-top: 8px; }
            }
          }

          &_Pages {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
          }

          ._inline {
            dt {
              display: inline-block;
              margin-right: 8px;
              width: 35%;
            }
            dd {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  ._darkMode .ViewIssueModal {
    &_Inner {
      &_Right {
        &_KeyIssueBtn {
          color: white;
        }
        &_List {
          &_Details {
            details {
              background: darken( $hugr-colours-primary, 10% );
              summary {
                color: white;
              }
            }
          }
          &_Pages {
            &_AddPage {
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
